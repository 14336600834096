import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';

const RegisterJWT = (props) => {
  const { register, closeRegisterDialog, openSuccessRegisterDialog } = useAuth();

  return (
    <Formik
      initialValues={{
        firstName: '',
        surname: '',
        email: '',
        username: '',
        phoneNumber: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          firstName: Yup
            .string()
            .max(255)
            .required('Fisrtname is required'),
          surname: Yup
            .string()
            .max(255)
            .required('Surname is required'),
          email: Yup
            .string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          username: Yup
            .string()
            .max(255)
            .required('Username is required'),
          phoneNumber: Yup
            .string()
            .max(255)
            .required('Phone number is required'),
          password: Yup
            .string()
            .min(7)
            .max(255)
            .required('Password is required'),
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await register(values.firstName, values.surname, values.email, values.username, values.phoneNumber, values.password);
          closeRegisterDialog();
          openSuccessRegisterDialog();
          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >

          <TextField
            error={Boolean(touched.firstName && errors.firstName)}
            fullWidth
            helperText={touched.firstName && errors.firstName}
            label="Firstname"
            margin="normal"
            name="firstName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.firstName}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.surname && errors.surname)}
            fullWidth
            helperText={touched.surname && errors.surname}
            label="Surname"
            margin="normal"
            name="surname"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.surname}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            label="Username"
            margin="normal"
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.username}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            fullWidth
            helperText={touched.phoneNumber && errors.phoneNumber}
            label="Phone number"
            margin="normal"
            name="phoneNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.phoneNumber}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            inputProps={{
              autoComplete: 'password',
              form: {
                autoComplete: 'off',
              },
            }}
          />
          {errors.submit && (
          <Box sx={{ mt: 3 }}>
            <FormHelperText error>
              {errors.submit}
            </FormHelperText>
          </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Register
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default RegisterJWT;
