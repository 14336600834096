import { Link as RouterLink, } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, Divider, IconButton, Container, Avatar } from '@material-ui/core';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import TimelineIcon from '@material-ui/icons/Timeline';
import useSettings from '../../../hooks/useSettings';
import AccountPopover from './AccountPopover';
import useAuth from '../../../hooks/useAuth';
import Logo from '../../../components/Logo';
import { useQuery } from '@apollo/client';
import { GET_BALANCE } from '../../../graphql/queries/user';
import { useEffect, useRef } from 'react';
import Cookies from 'js-cookie';

const NavBar = (props) => {
  const { onClickOpenSidebar } = props;
  const { settings } = useSettings();
  const { isAuthenticated, openLoginDialog, openRegisterDialog } = useAuth();
  const interval = useRef();

  const { data, refetch } = useQuery(GET_BALANCE, {
    skip: !Cookies.get('accessToken')
  });

  useEffect(() => {
    if (isAuthenticated) {
      interval.current = setInterval(() => {
        refetch();
      }, 10000);
    } else {
      clearInterval(interval.current);
    }
  }, [isAuthenticated]);

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >
      <Container maxWidth={settings.compact ? 'xl' : false}>
        <Box sx={{ display: 'flex', minHeight: 64, alignItems: 'center' }}>
          <Box
            sx={{
              pr: 2,
              display: {
                lg: 'block',
                xs: 'none'
              }
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 50,
                }}
              />
            </RouterLink>
          </Box>

          <IconButton
            color="inherit"
            onClick={onClickOpenSidebar}
          >
            <MenuOpenIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              flexGrow: 1,
              ml: 2
            }}
          />
          <>
            {
            isAuthenticated
              ? (
                <>
                  {false && (
                  <Box ml={1}>
                    <Button
                      color="primary"
                      component={RouterLink}
                      to="/profile"
                      size="small"
                      endIcon={(

                        // TODO need refactor
                        <Avatar
                          sx={{
                            height: 21,
                            width: 21,
                            fontSize: '0.56rem !important',
                            backgroundColor: 'transparent',
                            borderWidth: '1px',
                            borderColor: 'primary.main',
                            borderStyle: 'solid',
                            color: 'primary.main',
                          }}
                        >
                          MT
                        </Avatar>
                      )}
                    >
                      Balance:
                      {' '}
                      {data?.balance?.totalBalance}
                    </Button>
                  </Box>
                  )}
                  {false && (
                  <Box ml={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<TimelineIcon fontSize="small" />}
                      component={RouterLink}
                      to="/history"
                    >
                      History Timeline
                    </Button>
                  </Box>
                  )}
                  <Box sx={{
                    ml: {
                      xs: 1,
                      md: 2
                    }
                  }}
                  >
                    <AccountPopover />
                  </Box>
                </>
              )
              : (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={openLoginDialog}
                  >
                    Sign In
                  </Button>
                  <Box ml={1}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={openRegisterDialog}
                    >
                      Create Account
                    </Button>
                  </Box>
                </>
              )
          }
          </>

        </Box>
      </Container>
      <Divider />
    </AppBar>
  );
};

NavBar.propTypes = {
  onClickOpenSidebar: PropTypes.func,
};

export default NavBar;
