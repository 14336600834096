import { useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import Lottie from 'react-lottie-player';
import { Box, Card, CardContent, Dialog, Typography } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Login from '../../components/login/Login';
import Register from '../../components/register/Register';
import lottieJson from './16271-payment-successful.json';
import SettingsDrawer from '../../components/SettingsDrawer';

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64
}));

const MainLayout = ({ children }) => {
  const { isLoginDialogOpen, closeLoginDialog, isRegisterDialogOpen, closeRegisterDialog, isSuccessRegisterDialogOpen, closeSuccessRegisterDialog } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MainLayoutRoot>
      <Navbar
        onClickOpenSidebar={() => setIsOpen(true)}
      />
      <Sidebar
        onClose={() => setIsOpen(false)}
        open={isOpen}
      />

      <Dialog
        open={isLoginDialogOpen}
        onClose={closeLoginDialog}
      >
        <Login />
      </Dialog>

      <Dialog
        open={isRegisterDialogOpen}
        onClose={closeRegisterDialog}
      >
        <Register />
      </Dialog>

      <Dialog
        open={isSuccessRegisterDialogOpen}
        onClose={closeSuccessRegisterDialog}
      >
        <Card>
          <CardContent>
            <Box sx={{ textAlign: 'center', padding: '2rem 2rem 3rem' }}>
              <Lottie
                animationData={lottieJson}
                loop={false}
                play
                style={{ width: '10rem', margin: '0 auto' }}
              />

              <Typography variant="h3">
                Success
              </Typography>

              <Typography
                color="textSecondary"
                sx={{ mt: '1rem' }}
              >
                You have successfully joined MTCoinplay
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Dialog>

      {children || <Outlet />}

      <SettingsDrawer />

    </MainLayoutRoot>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
