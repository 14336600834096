import PropTypes from 'prop-types';
import { List, ListSubheader } from '@material-ui/core';
import NavItem from './NavItem';

const NavSection = (props) => {
  const { items, title, ...other } = props;

  return (
    <List
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase',
            pl: 1
          }}
        >
          {title}
        </ListSubheader>
      )}
      {...other}
    >
      <List disablePadding>
        {items.map((item, i) => (
          <NavItem
            icon={item.icon}
            key={`navitem-${JSON.stringify(i)}`}
            gameProviderId={item.gameProviderId}
            id={item.id}
            title={item.title}
          />
        ))}
      </List>
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string
};

export default NavSection;
