import { gql } from '@apollo/client';

export const INIT_PROVIDER = gql`
    mutation InitProvider($model: InitProviderModelInput) {
        initProvider(model: $model) {
            iframeUrl
            code
            currencyCode
            entityId
            username
        }
    }
`;
