import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';

const LoginJWT = (props) => {
  const { onLogin } = props;

  const { login, closeLoginDialog } = useAuth();

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          username: Yup
            .string()
            .max(255)
            .required('Username is required'),
          password: Yup
            .string()
            .max(255)
            .required('Password is required')
        })}
      // eslint-disable-next-line no-unused-vars
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const token = await login(values.username, values.password);
          closeLoginDialog();
          setStatus({ success: true });
          setSubmitting(false);

          onLogin?.(token);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            label="Username"
            margin="normal"
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.username}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

LoginJWT.propTypes = {
  login: PropTypes.func,
  onLogin: PropTypes.func,
};

export default LoginJWT;
