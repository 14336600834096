import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import getInitials from '../../../utils/getInitials';
import useAuth from '../../../hooks/useAuth';
import NavSection from './NavSection';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import Logo from '../../../components/Logo';
import { GET_MAIN_GAMES } from '../../../graphql/queries/gameDirectory';

const DashboardSidebar = (props) => {
  const { onClose, open } = props;
  const location = useLocation();
  const { isAuthenticated, user } = useAuth();
  const { data } = useQuery(GET_MAIN_GAMES);

  useEffect(() => {
    if (open && onClose) {
      onClose();
    }
  }, [location.pathname]);

  const sections = data?.mainGames ? [
    {
      title: 'Games',
      items: data.mainGames.map((game) => ({
        title: game.name,
        gameProviderId: game.provider.id,
        id: game.id,
        // icon: <PlayArrowIcon fontSize="small" />
      }))
    },
  ] : [];

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 50,
              }}
            />
          </RouterLink>
        </Box>
        {isAuthenticated
        && (
        <Box sx={{ px: 2, pb: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <Avatar
              sx={{
                height: 48,
                width: 48,
                backgroundColor: 'primary.main',
                color: 'primary.contrastText'
              }}
            >
              {getInitials(user.firstName)}
            </Avatar>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.firstName}
                {' '}

                {user.surname}
              </Typography>
            </Box>
          </Box>
        </Box>
        )}
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <>
              <NavSection
                key={section.id}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3
                  }
                }}
                {...section}
              />
            </>
          ))}
        </Box>

      </Scrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default DashboardSidebar;
