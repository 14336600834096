import { Box, Card, CardContent, Divider, Link, Typography } from '@material-ui/core';
import LoginWithJWT from './LoginWithJWT';
import useAuth from '../../hooks/useAuth';

const Login = () => {
  const { openRegisterDialog, closeLoginDialog } = useAuth();

  const handleClickRegisterButton = () => {
    openRegisterDialog();
    closeLoginDialog();
  };

  return (
    <Card sx={{
      width: {
        md: 500,
        xs: '100%'
      },
      overflowY: 'auto'
    }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 4
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            mb: 3
          }}
        >
          <div>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              Log in
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Log in on the internal platform
            </Typography>
          </div>
          <Box
            sx={{
              height: 32,
              '& > img': {
                maxHeight: '100%',
                width: 'auto'
              }
            }}
          >
            <img
              alt="Auth platform"
              src="/static/icons/jwt.svg"
            />
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            mt: 3
          }}
        >
          <LoginWithJWT />
        </Box>
        <Divider sx={{ my: 3 }} />
        <Link
          color="textSecondary"
          component="button"
          onClick={handleClickRegisterButton}
          variant="body2"
          sx={{
            textAlign: 'left'
          }}
        >
          Create new account
        </Link>
      </CardContent>
    </Card>
  );
};

export default Login;
