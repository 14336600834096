/* eslint-disable */

import { Suspense, lazy } from "react";
import AuthGuard from "./components/auth-guard/AuthGuard";
import MainLayout from "./layouts/main/MainLayout";
import LoadingScreen from "./components/LoadingScreen";
import MazePage from "./pages/Maze";
import SquidGamePage from "./pages/SquidGame";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const HomePage = Loadable(lazy(() => import("./pages/HomePage")));
const FantasyPage = Loadable(lazy(() => import("./pages/FantasyPage")));
const PlayRoulettePage = Loadable(
  lazy(() => import("./pages/PlayRoulettePage"))
);
const LobbyPage = Loadable(lazy(() => import("./pages/LobbyPage")));
const LobbyDevPage = Loadable(lazy(() => import("./pages/LobbyDevPage")));
const HistoryPage = Loadable(lazy(() => import("./pages/HistoryPage")));
const AccountPage = Loadable(lazy(() => import("./pages/AccountPage")));
const TitanCPage = Loadable(lazy(() => import("./pages/TitanC")));
const CrazyMonkeyPage = Loadable(lazy(() => import("./pages/CrazyMonkey")));

const routes = [
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/fantasy",
        element: <FantasyPage />,
      },
      {
        path: "/lobby/game/:game/provider/:provider",
        element: (
          <AuthGuard>
            <LobbyPage />
          </AuthGuard>
        ),
      },
      {
        path: "/dev",
        element: (
          <AuthGuard>
            <LobbyDevPage />
          </AuthGuard>
        ),
      },

      {
        path: "/history",
        element: (
          <AuthGuard>
            <HistoryPage />
          </AuthGuard>
        ),
      },
      {
        path: "/account",
        element: (
          <AuthGuard>
            <AccountPage />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "/play-roulette",
    element: <PlayRoulettePage />,
  },
  {
    path: "/titanc",
    element: (
      <AuthGuard>
        <TitanCPage />
      </AuthGuard>
    ),
  },
  {
    path: "/maze",
    element: (
      <AuthGuard>
        <MazePage />
      </AuthGuard>
    ),
  },
  {
    path: "/squid-game",
    element: (
      <AuthGuard>
        <SquidGamePage />
      </AuthGuard>
    ),
  },
  {
    path: "/crazy-monkey",
    element: (
      <AuthGuard>
        <CrazyMonkeyPage />
      </AuthGuard>
    ),
  },
];

export default routes;
