import { gql } from '@apollo/client';

export const GET_GAMES = gql`
  query GetGames(
    $after: String
    $before: String
    $first: Int
    $order: [GameDtoSortInput!]
    $last: Int
    $where: GameDtoFilterInput
  ) {
    games(
      after: $after
      before: $before
      first: $first
      order: $order
      last: $last
      where: $where
    ) {
      nodes {
        name
        id
        imagePath
        type
        provider {
          name
          id
        }
      }
    }
  }
`;
export const GET_MAIN_GAMES = gql`
query GetMainGames {
    mainGames {
      name
      id
      imagePath
      type
      provider {
        name
        id
      }
    }
  }
`;
